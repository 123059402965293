/*
 * jQuery shop v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, DUNLOP){
	const mapSite = DUNLOP.va.pathname.split('/')[1];
	const mapCat = DUNLOP.va.pathname.split('/')[3];

	// ＜共通＞お知らせがない場合メッセージ表示
	const topNewsBlockDelete = () => {
		if($('.mod-topNews').length) {
			if(!$('.mod-topNews').find('.mod-topNews-li')[0]) {
				$('.mod-topNews').append('<li class="ta_center">ただ今のお知らせはございません</li>');
				$('.mod-topNews-all').remove();
			}
		}
	};
	// ＜共通＞スライダー
	const setSlider = () => {
		const arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
		const arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';

		// ＜ゴルフスクール・テニススクール＞スクール写真
		$('.shop-detail-info-photo-slider').slick({
			infinite: true,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			arrows: false,
			dots: true,
		});

		// ＜ゴルフスクール＞タイムテーブル
		$('.shop-detail-timetable-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});
	};
	// ＜golf 店舗検索フォーム＞
	const searchGolfShopForm = () => {
		let paramPref = '';
		let paramRetail = '';

		const checkRetail = () => {
			if($('#retail_01').prop('checked')) {
				paramRetail = '&retail=0&retail=1';
			} else if($('#retail_02').prop('checked')) {
				paramRetail = '&retail=1';
			}
		};

		// パラメータチェック＆フォームへ反映
		if(location.search.indexOf('retail=0') !== -1) {
			$('#retail_01').prop('checked', true);
			$('#retail_02').prop('checked', false);
		}

		if($('#pref').val() !== '') {
			paramPref = `pref=${$('#pref').val()}`;
		}
		$('#pref').on('change', () => {
			if($('#pref').val() !== '') {
				paramPref = `pref=${$('#pref').val()}`;
			}
		});

		checkRetail();
		$('#retail_01, #retail_02').on('change', () => {
			checkRetail();
		});

		$('.js-submit').on('click', () => {
			if(!paramPref) {
				alert('都道府県を選択してください。');
			} else {
				location.href = `/golf/shop/list.html?${paramPref}${paramRetail}`;
			}
		});

		$('.js-clear').on('click', () => {
			$('#retail_01, #retail_02').prop('checked', false);
			$('#pref').val('');
			paramPref = '';
			paramRetail = '';
		});
	};
	const searchGolfShopResult = () => {
		let requireTxt = '';
		if($('#pref').val() !== '') {
			requireTxt += `${$('#pref').find(`option[value="${$('#pref').val()}"]`).text()}`;
		}
		if($('#retail_01').prop('checked')) {
			requireTxt += '、すべての店舗';
		} else if($('#retail_02').prop('checked')) {
			requireTxt += '、直営店のみ';
		}
		$('.js-requireTxt').text(requireTxt);
	};
	// ＜golfschool スクール検索フォーム＞
	const searchGolfSchoolForm = () => {
		$('#form-searchSchool').submit(() => {
			if($('#pref').val() === '') {
				alert('都道府県を選択してください。');
				return false;
			} else {
				return true;
			}
		});
		$('.js-clear').on('click', () => {
			$('#pref').val('');
			$('.mod-check-input').prop('checked', false);
		});
	};
	const searchGolfSchoolResult = () => {
		let requireTxt = '';
		requireTxt += $('#pref').find(`option[value="${$('#pref').val()}"]`).text();
		$('#form-searchSchool').find('.mod-check').each((idx, ele) => {
			if($(ele).find('.mod-check-input').prop('checked')) {
				requireTxt += `、${$(ele).find('.mod-check-text .label').text()}`;
			}
		});
		$('.js-requireTxt').text(requireTxt);
	};
	// ＜golfschool tennisschool 空のタグ消し込み＞
	const removeEmptyTags = () => {
		if($('.cf_s_tags').length) {
			$('.cf_s_tags').each((idx, ele) => {
				if(!$(ele).find('span')[0]) {
					$(ele).remove();
				}
			});
		}
	};
	// ＜golfschool スクール詳細 体験申込みフォームリンクのパラメータ作成＞
	const setParamEntryForm = () => {
		$('.js-entry').attr(
			'href',
			`/golfschool/entry/form/?pref=${encodeURIComponent($('.rt_cf_s_prefecture').text())}`+
			`&school=${encodeURIComponent($('.shop-detail-head-name').text())}`
		);
	};
	// ＜sportscasual 店舗検索＞
	const searchSportscasualShopForm = () => {
		// 都道府県
		let paramPref = '';
		if ($('#pref').val() !== '') {
			paramPref = `pref=${$('#pref').val()}`;
		}
		$('#pref').on('change', () => {
			paramPref = `p_pref=${$('#pref').val()}`;
		});

		// カテゴリ（AJAX）
		let paramCat = '';
		const getJSONSportsCatList = () => {
			return $.getJSON('/sports_casual/ajax/shop_tag.json');
		};
		getJSONSportsCatList().then((data) => {
			if (data.rt_bn_shop_tags.length) {
				let selectCatList = '';
				for (let i = 0; i < data.rt_bn_shop_tags.length; i++) {
					selectCatList += `<option value="${data.rt_bn_shop_tags[i]._key}">${data.rt_bn_shop_tags[i].cf_st_name_d}</option>`;
				}
				$('#category').append(selectCatList);
				$('#category').on('change', () => {
					if ($('#category').val() !== '') {
						paramCat = `&p_tag=${$('#category').val()}`;
					} else {
						paramCat = '';
					}
				});
				// 一覧ページ パラメータ反映
				if (location.search) {
					let thisParamArr = location.search.split('&');
					let requireWord = '';
					for (let i = 0; i < thisParamArr.length; i++) {
						if (thisParamArr[i].indexOf('p_pref=') !== -1) {
							$('#pref').val(thisParamArr[i].split('=')[1]);
							paramPref = `p_pref=${$('#pref').val()}`;
							requireWord += `${$('#pref option:selected').text()}、`;
						} else if (thisParamArr[i].indexOf('p_tag=') !== -1) {
							$('#category').val(thisParamArr[i].split('=')[1]);
							paramCat = `&p_tag=${$('#category').val()}`;
							requireWord += `${$('#category option:selected').text()}、`;
						}
					}
					$('.js-requireTxt').append(requireWord.slice(0, -1));
				}
			}
		});

		$('.js-submit').on('click', () => {
			if ($('#pref').val() === '') {
				alert('都道府県を選択してください。');
			} else {
				location.href = `/sportscasual/shop/list.html?${paramPref}${paramCat}`;
			}
		});
		$('.js-clear').on('click', () => {
			$('#pref').val('');
			$('#category').val('');
			paramPref = '';
			paramCat = '';
		});
	};

	if(mapSite === 'golf') {
		if(mapCat === '' || mapCat === 'index.html') { // ゴルフ販売店TOP
			searchGolfShopForm();
		} else if(mapCat === 'list.html') { // ゴルフ販売店検索結果
			searchGolfShopForm();
			searchGolfShopResult();
		} else if(mapCat === 'retail') { // ゴルフ直営店詳細
			topNewsBlockDelete();

			// クラブドクター、販売スタッフ情報が存在しないときにメッセージ表示
			if(!$('.rt_cf_clubdoctor_is').find('li').length) {
				$('.rt_cf_clubdoctor_is').remove();
				$('.rt_cf_clubdoctor_is_not').removeClass('di_none');
			}
			if(!$('.rt_cf_salesstaff_is').find('li').length) {
				$('.rt_cf_salesstaff_is').remove();
				$('.rt_cf_salesstaff_is_not').removeClass('di_none');
			}
		}
	} else if(mapSite === 'wellness') {
		if(location.search) {
			$('.js-toggle').addClass('is-active');
			$('.js-toggleBlock').addClass('is-active');
		}
	} else if(mapSite === 'golfschool') {
		if(mapCat === '' || mapCat === 'index.html') { // ゴルフスクール検索TOP
			searchGolfSchoolForm();
		} else if(mapCat === 'search.html') { // ゴルフスクール検索結果
			searchGolfSchoolForm();
			searchGolfSchoolResult();
			removeEmptyTags();
		} else if(mapCat === 'detail') { // ゴルフスクール詳細
			setParamEntryForm();
			removeEmptyTags();
			topNewsBlockDelete();
			setSlider();

			// フリーエリアが空なら削除
			if(!$('.shop-detail-free').find('.rt_cf_s_introduction').length) {
				$('.shop-detail-free').remove();
			}
		}
	} else if(mapSite === 'tennisschool') {
		if(mapCat === '' || mapCat === 'index.html') { // テニススクール一覧
			removeEmptyTags();
		}
	} else if(mapSite === 'ts') { // テニススクール詳細
		topNewsBlockDelete();
		setSlider();
	} else if(mapSite === 'sportscasual') {
		searchSportscasualShopForm();
	}

})(window.jQuery, window.DUNLOP);
